.company-main-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.title-copmany1 {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
  font-weight: bold;
}
.title-company2 {
  text-align: center;
}
.list-company {
  width: 300px;
  height: 300px;
}
.anha {
  width: 300px;
  height: 300px;
  object-fit: contain;
  border-radius: 5px;
}
.box2-company {
  display: flex;
  justify-content: space-between;
}
.b {
  margin-left: 50px;
  margin-right: 50px;
}
.c {
  margin-right: 50px;
}
.k {
  margin-left: 25px;
}
.title-company3 {
  margin-top: 30px;
}
.title-company3-1 {
  font-size: 13px;
  font-weight: bold;
}
.title-company3-2 {
  font-size: 30px;
}
.list-comment {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  gap: 50px;
}
.comment1,
.comment2,
.comment3 {
  width: 700px;
}
.text-comment {
  text-align: center;
}
.list-company-text {
  text-align: center;
}
.list-company-name {
  font-size: 18px;
  font-weight: bold;
}
.box-company4 {
  margin-top: 50px;
}
.title-electric {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.title-company4 {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-top: 50px;
}
.description-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.description-list1,
.description-list2,
.description-list3 {
  width: 450px;
}
.description-img1 {
  width: 450px;
}
.des1 {
  width: 400px;
  height: 400px;
}
.description-img1 img {
  width: 450px;
  height: 400px;
}
.description-img2 img {
  width: 450px;
  height: 400px;
}
.description-img3 {
  width: 450px;
}
.description-img3 img {
  width: 450px;
  height: 400px;
}
.description-text1-1,
.description-text2-1,
.description-text3-1 {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}
.description-text1-2,
.description-text2-2,
.description-text3-2 {
  text-align: center;
}
.title-company5 {
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
}
.box5-list {
  display: flex;
  justify-content: space-between;
}
.box5-list1 {
  position: relative;
  width: 300px;
  height: 350px;
  margin-top: 20px;
}
.box5-list-img img {
  width: 100%;
  height: 225px;
  object-fit: cover;
}
.box5-list-text {
  position: absolute;
  bottom: 135px;
  left: 60px;
  color: aliceblue;
  text-align: center;
  font-weight: bold;
}
.box5-list-text1 {
  font-size: 25px;
}
.box5-list-text2 {
  color: black;
  background-color: aliceblue;
}
.box2-company {
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .company-main-container {
    max-width: 100%;
  }

  .list-comment {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .list-company,
  .anha {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .box-company2:hover .anha {
    display: none;
  }

  .box-company2:hover .a {
    display: block;
  }

  .description-list1,
  .description-list2,
  .description-list3 {
    width: 300px;
  }

  .description-img1 img,
  .description-img2 img,
  .description-img3 img {
    width: 300px;
    height: 300px;
  }

  .comment1,
  .comment2,
  .comment3 {
    width: 300px;
  }

  .box5-list {
    flex-direction: column;
  }

  .box5-list1 {
    width: 230px;
    height: 230px;
  }

  .box5-list-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .title-copmany1 {
    font-size: 30px;
  }

  .box2-company {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  .list-company {
    width: 200px;
    height: 200px;
    margin-left: 80px;
  }
  .anha {
    object-fit: contain;
  }
  .list-comment {
    gap: 10px;
  }
  .description-list1,
  .description-list2,
  .description-list3 {
    width: 250px;
  }
  .description-img1 img,
  .description-img2 img,
  .description-img3 img {
    width: 250px;
    height: 250px;
  }
  .box5-list1 {
    width: 200px;
    height: 200px;
  }
  .box5-list-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .box5-list-text {
    color: aliceblue;
    text-align: center;
    font-weight: bold;
  }
  .box5-list-text1 {
    font-size: 15px;
  }
  .title-electric {
    font-size: 20px;
  }
  .box5-list1 {
    width: 100%;
  }
  .title-company4,
  .title-company5,
  .title-copmany1 {
    font-size: 20px;
  }
}
