/* Bao bọc bên ngoài của footer */
.footer-wrapper {
  max-width: 1928px; /* Chiều rộng tối đa của toàn bộ footer */
  margin: 0 auto; /* Căn giữa footer trên trang */
  width: 100%;
}

/* Nền và nội dung của footer */
.footer {
  background-color: rgb(255, 255, 255); /* Nền trắng */
  padding-top: 2rem;
  max-width: 1928px;
  margin: 0 auto; /* Đảm bảo căn giữa */
  width: 100%;
}
.footer-section ul li {
  cursor: pointer; /* Con trỏ sẽ chuyển thành pointer khi hover */
}

.footer-section ul li:hover {
  text-decoration: underline; /* Thêm gạch chân khi hover, tùy chọn */
}

/* Bố cục chính của nội dung trong footer */
.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  max-width: 1400px; /* Đảm bảo nội dung bên trong không vượt quá 1400px */
  margin: 0 auto;
  padding: 0 1rem;
}

/* Bố cục từng phần (section) trong footer */
.footer-section {
  flex: 1;
  min-width: 200px;
  padding-left: 10px; /* Đảm bảo lề chung cho cả h2 và ul */
}

.footer-logo {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: 0; /* Đảm bảo h2 không có lề thêm */
}

/* Phần form của newsletter */
.newsletter-form {
  display: flex;
  margin-bottom: 0.5rem;
}

.newsletter-form input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-right: none;
}

.newsletter-form button {
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}

.newsletter-text {
  font-size: 0.8rem;
  color: #666;
}

.footer-section h2 {
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-left: 0; /* Đảm bảo h2 không có lề thêm */
}

.footer-section ul {
  list-style-type: none;
  padding-left: 0; /* Loại bỏ lề mặc định của ul */
  margin-left: 0;  /* Đảm bảo không có lề trái bổ sung */
}

.footer-section li {
  margin-bottom: 0.25rem;
  padding-left: 0;
  padding-bottom: 15px;
  padding-top: 5px;
}

/* Nút chia sẻ trang */
.share-page {
  margin-top: 1rem;
}

.share-button {
  width: 100%; /* Đặt chiều rộng của nút chiếm toàn bộ div */
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: center; /* Canh giữa nội dung trong nút */
  align-items: center; /* Canh giữa theo chiều dọc */
}

.share-icon {
  margin-right: 0.5rem;
  color: #fff;
  background-color: #333;
}

/* Kết nối mạng xã hội */
.connect-section {
  margin-top: 1rem;
}

.connect-section p {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.social-icons {
  display: flex;
  gap: 0.5rem;
}

.social-icons svg {
  width: 50px;  /* Chiều rộng của icon */
  height: 50px; /* Chiều cao của icon */
  color: #fff;
  background-color: #333; /* Màu nền của icon */
  padding: 10px;  /* Khoảng cách giữa icon và khung */
  box-sizing: border-box; /* Đảm bảo padding không ảnh hưởng tới kích thước */
}

.social-icons svg:hover {
  background-color: #666; /* Thay đổi màu nền khi hover */
  color: #fff;
}

.Change-country {
  font-size: 22px;
}

.aria-label {
  padding: 0;
}

.footer-region {
  color: white;
  display: flex;
  flex-direction: column;
}

/* Nền của phần footer-bottom */
.footer-bottom {
  background-color: #191f22;
  height: 283px;
  padding-bottom: 100px;
  width: 100%;
}

/* Container giới hạn nội dung bên trong footer-bottom */
.footer-bottomm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #191f22;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1400px; /* Chiều rộng tối đa của nội dung */
  margin: 1rem auto 0;
  padding: 1rem;
}

/* Đường ngang phân cách footer */
.footer-divider {
  border: none;
  border-top: 1px solid #eee8e8; 
  margin: 1rem auto; 
  width: 1360px; 
  max-width: 100%; 
  margin-bottom: 40px;
}

.footer-divider1 {
  border: none;
  border-top: 1px solid #4d4a4a; 
  margin: 1rem auto; 
  width: 1360px; 
  max-width: 100%; 
}

.footer-links a {
  margin-right: 1rem;
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline; /* Gạch chân khi hover */
}

.social-icons a {
  color: #fff; /* Màu trắng cho các biểu tượng mạng xã hội */
  text-decoration: none;
}

.social-icons a:hover {
  color: #ccc; /* Màu sáng hơn khi hover */
}

/* Responsive cho thiết bị di động và tablet */
@media (max-width: 768px) {
  .footer-content,
  .footer-bottomm {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-section,
  .footer-bottom > * {
    width: 100%;
    margin-bottom: 1rem;
  }

  .social-icons {
    justify-content: flex-start;
  }

  .share-button {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  .footer-content {
    padding: 0 0.5rem;
  }
}

/* Responsive cho màn hình lớn hơn (desktop và laptop) */
@media (min-width: 1200px) {
  .footer-content {
    max-width: 1400px;
    padding: 0 2rem;
  }
}
