.ata {
  display: flex;
  justify-content: space-between;
}
.title11111 {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 15px;
}
.title-finance {
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}
.price-finance {
  font-weight: bold;
  text-align: center;
  color: brown;
}
.img-finance img {
  width: 270px;
  height: 220px;
  margin-left: 10px;
  margin-top: 10px;
}
ul {
  margin-top: 10px;
  margin-left: 10px;
}
.booxx1,
.booxx2,
.booxx3,
.booxx4 {
  width: 300px;
}
.dam {
  font-weight: bold;
}
.butts {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .ata {
    align-items: center;
  }
  .title11111 {
    font-size: 22px;
  }
  .booxx1 {
    margin-top: -130px;
  }
  .title-finance {
    font-size: 13px;
  }
  .price-finance {
    font-size: 14px;
  }
  .img-finance img {
    width: 200px;
    height: 230px;
    margin-left: 0;
    margin-top: 10px;
  }
  ul {
    margin-left: 20px;
  }
  .booxx1,
  .booxx2,
  .booxx3,
  .booxx4 {
    width: 100%;
    max-width: 280px;
  }
  .butts {
    display: flex;
    gap: 50px;
    justify-content: center;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .ata {
    align-items: center;
  }
  .title11111 {
    font-size: 20px;
  }
  .title-finance {
    font-size: 12px;
  }
  .price-finance {
    font-size: 12px;
  }
  .img-finance img {
    width: 180px;
    height: 200px;
    margin-left: 0;
    margin-top: 10px;
  }
  ul {
    margin-left: 15px;
  }
  .booxx1,
  .booxx2,
  .booxx3,
  .booxx4 {
    width: 100%;
    max-width: 260px;
  }
  .buttt1,
  .buttt2 {
    margin-left: 0;
    text-align: center;
  }
}
/* nhap */
.price-install,
.price-plan {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
.installment-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.installment-header {
  margin-bottom: 10px;
}

.installment-header label {
  font-weight: bold;
}

.installment-header input {
  width: 200px;
  margin-left: 10px;
}

.installment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.installment-table th,
.installment-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.installment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.bo1 {
  display: flex;
  gap: 410px;
}
.bo1 h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
}
