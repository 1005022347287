.bmw_main_finance {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.title-finance-bmw2 {
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}
.finance-bmw-img {
  display: flex;
  margin-top: 20px;
}
.finance-bmw-img1 img,
.finance-bmw-img2 img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.finance-bmw-img2 img {
  margin-left: 100px;
}
.finance-bmw-text {
  margin-top: 20px;
}
.finance-bmw-text12,
.finance-bmw-text22 {
  margin-left: 10px;
}
.but {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 30px;
}
.but1 {
  height: 50px;
  background-color: gray;
  margin-left: 250px;
  margin-right: 40px
}
.but2 {
  height: 50px;
}

@media screen and (max-width: 1024px) {
  .finance-bmw-img1 img,
  .finance-bmw-img2 img {
    width: 100%;
    max-width: 500px;
    height: 350px;
  }

  .finance-bmw-img {
    justify-content: center;
  }

  .but1 {
    max-width: 180px;
  }
  .but2 {
    max-width: 210px;
  }
}

@media screen and (max-width: 768px) {
  .finance-bmw-img {
    flex-direction: column;
    align-items: center;
  }

  .finance-bmw-img2 img {
    margin-left: 0;
    margin-top: 20px;
  }

  .but {
    flex-direction: column;
    align-items: center;
  }

  .but1 {
    margin-top: 10px;
    width: 100%;
    max-width: 180px;
  }
  .but2 {
    max-width: 210px;
    margin-left: 10px;
    margin-top: 10px;
  }
}
