.hero-section {
  position: relative;
  max-width: 1928px;
  height: 95vh;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.hero-image.active {
  opacity: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  z-index: 1; /* Đảm bảo lớp phủ này nằm dưới phần chữ nhưng trên ảnh */
}

/* Thêm style cho phần tiêu đề */
.hero-title {
  position: absolute;
  bottom: 450px;
  left: 90px;
  color: rgb(255, 255, 255);
  z-index: 2; /* Giữ nguyên hoặc đảm bảo giá trị lớn hơn 1 */
  font-size: 90px;
  font-weight: bold;
  padding: 10px 20px;
  padding-left: 40px;
  
  width: 550px;
  height: 250px;
  justify-items: center;
  align-items: center;
}


/* Style cho các chỉ số của banner */
.hero-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 3;
}

.hero-indicator {
  width: 30px;
  height: 30px;
  border: none;
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-indicator.active {
  background-color: white;
  color: black;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.hero-indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: white;
}
