
.location-banner {
  position: relative; 
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden; 
}

.location-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: center; 
  border-radius: 0; 
}


.location-banner-text1 {
  position: absolute;
  top: 50%; 
  left: 10%; 
  transform: translate(-50%, -50%);
  color: white; 
  font-size: 24px; 
  font-weight: bold;
  text-align: center;
  z-index: 1; 
}
.location-banner-text2 {
  position: absolute;
  top: 60%; 
  left: 12%; 
  transform: translate(-50%, -50%); 
  color: white; 
  font-size: 16px; 
  text-align: left;
  z-index: 1;
}
.Location-Info {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
}
.header-wrapper {
  max-width: 1928px;
  margin: 0 auto; 
  width: 100%;
}
.Location-Container {
  margin-top: 6px;
  max-width: 1928px; 
  margin: 0 auto;
  width: 100%;
}

.ant-card {
  text-align: left;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 0;
}

.ant-card-body {
  padding: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.ant-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Logo Section */
.location-logo-section {
  width: 376px;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  height: 250px;
  overflow: hidden;
}

.location-logo {
  max-width: 100%; 
  max-height: 100%; 
  object-fit: contain;
  border-radius: 0;
}


.location-info-section h2 {
  font-size: 24px;
}

.location-info-section p {
  padding-top: 10px;
}

.location-telephone {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.location-details {
  text-align: right;
}

/* Responsive Design - Media Queries */

/* For small devices like phones */
@media (max-width: 576px) {
  .Location-Info {
    padding-left: 10px;
    padding-right: 10px;
  }

  .location-logo-section {
    width: 100%;
    height: 200px;
  }

  .location-logo {
    max-height: 100%;
  }

  .location-info-section h2 {
    font-size: 18px;
  }

  .location-telephone {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* For tablets */
@media (max-width: 768px) {
  .Location-Info {
    padding-left: 20px;
    padding-right: 20px;
  }

  .location-logo-section {
    width: 100%;
    height: 225px;
  }

  .location-info-section h2 {
    font-size: 20px;
  }

  .location-telephone {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

/* For medium devices like small desktops */
@media (max-width: 992px) {
  .Location-Info {
    padding-left: 30px;
    padding-right: 30px;
  }

  .location-logo-section {
    width: 100%;
    height: 250px;
  }

  .location-info-section h2 {
    font-size: 22px;
  }

  .location-telephone {
    font-size: 16px;
  }
}

/* For large desktops */
@media (min-width: 992px) {
  .Location-Info {
    padding-left: 40px;
    padding-right: 40px;
  }

  .location-logo-section {
    width: 376px;
    height: 250px;
  }
}
