/* Set header font sizes to 16px */
.ant-menu-item a,
.ant-menu-submenu-title {
  text-decoration: none !important; /* Remove underline */
  font-weight: normal !important; /* Regular font weight */
  font-size: 16px !important; /* Set font size to 16px */
}
/* Trạng thái bình thường - Không hiển thị gạch chân */
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light.ant-menu-horizontal > .ant-menu-item::after,
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item::after,
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after,
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 2px solid transparent !important; /* Không hiển thị gạch chân */
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Khi hover vào, hiển thị gạch chân */
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-ccdg5a).ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu:hover::after {
    border-bottom: 2px solid blue !important; /* Hiển thị gạch chân màu xanh khi hover */
}



/* Nếu muốn điều chỉnh thêm màu chữ khi hover hoặc active */
.ant-menu-item a:hover,
.ant-menu-item-selected a {
  color: inherit; /* Giữ nguyên màu sắc mà không thay đổi */
}

.ant-menu-item a:hover,
.ant-menu-submenu-title:hover {
  text-decoration: none !important; /* No underline on hover */
  font-weight: normal !important; /* Regular font weight on hover */
}
.ant-menu-item-selected {
  border-bottom: none !important; /* Xóa gạch chân hoặc đường kẻ dưới */
  text-decoration: none !important; /* Xóa mọi gạch chân (nếu có) */
}

/* Handle ellipsis issue */
.ant-menu-item,
.ant-menu-submenu-title {
  flex-shrink: 0; /* Prevent shrinking */
  white-space: nowrap; /* Ensure no line wrapping */
}

/* Additional styling for inputs and buttons */
.ant-input {
  font-size: 16px; /* Set input font size to 16px */
}

.ant-btn {
  font-size: 16px; /* Set button font size to 16px */
}

/* Remove underline and ellipsis */
.ant-menu-item a,
.ant-menu-submenu-title a {
  text-decoration: none !important;
}

/* Fade menu effects */
.fade-menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.fade-menu.visible {
  opacity: 1;
  visibility: visible;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-submenu:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-selected {
  background-color: transparent !important;
  border-bottom: none !important;
  text-decoration: none !important; 
}

.ant-menu-item a:hover,
.ant-menu-submenu-title:hover {
  text-decoration: none !important;
}

/* Responsive styling */
@media (max-width: 1024px) {
  .ant-menu-horizontal {
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    display: block;
  }

  .header {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  /* Ensure the search bar is visible on smaller screens */
  .search-bar {
    display: block !important; /* Show search bar on mobile */
    width: 100%; /* Full width on mobile */
  }

  /* Adjust logo size for mobile */
  .header img {
    width: 100px; /* Set logo width for smaller screens */
    max-width: 100%; /* Ensure it doesn't exceed container */
    height: auto; /* Maintain aspect ratio */
    max-height: 60px; /* Set a max height */
  }
}

@media (min-width: 1024px) {
  .ant-menu-horizontal {
    display: flex;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    display: inline-block;
  }

  .search-bar {
    display: block;
    width: auto; /* Keep regular width on larger screens */
  }

  /* Adjust logo size for larger screens */
  .header img {
    width: 160px; /* Set logo width for larger screens */
    max-width: 100%; /* Ensure it doesn't exceed container */
    height: auto; /* Maintain aspect ratio */
  }
}

/* Other styles */
.all-products {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eff0f1;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-sanpham {
  align-items: center;
  width: 1400px;
  font-size: 26px;
  padding: 10px 20px;
}
.logo {
  height: 50px;
  width: auto;
  max-height: 100%;
}

@media (min-width: 1024px) {
  .logo {
    height: 60px;
  }
}
