/* Audi_main.css */
html {
  font-size: 16px;
}

body {
  font-size: 1rem;
  line-height: 1.5;
}

.audi-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Intro Section */
.intro-section {
  text-align: center;
  margin-bottom: 40px;
}

.intro-section h2 {
  font-size: 2rem;
  color: #000;
  text-transform: uppercase;
 
}

.intro-section p {
  color: #555;
}

/* Service Details */
.service-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.service-image {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.service-info {
  width: 100%;
}

.service-info h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-info ul {
  list-style-type: disc;
  margin-left: 20px;
}

/* Service Plans */
.service-plans {
  text-align: center;
  margin-bottom: 40px;
}

.service-plans h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
 
}

.plans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.plan img {
  width: 100%;
  max-width: 200px;
  margin-bottom: 15px;
}

/* Customer Support */
.customer-support {
  text-align: center;
  margin-bottom: 40px;
}

.customer-support h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
 
}

.support-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.support-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease;
}

.support-option,
.campaign {
  width: 100%;
  max-width: 600px; /* Hoặc kích thước phù hợp với thiết kế của bạn */
  margin: 0 auto 20px;
}
/* Additional Support */
.additional-support {
  text-align: center;
  margin-bottom: 40px;
}

.additional-support h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;

}

.support-options,
.support-campaigns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Tăng minmax từ 250px lên 300px */
  gap: 30px; /* Tăng khoảng cách giữa các item */
}

.campaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease;
}

.campaign img {
  width: 100%;
  max-width: 200px;
  margin-bottom: 15px;
}

a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

/* Responsive Breakpoints */
@media (min-width: 768px) {
  .service-details {
    flex-direction: row;
    align-items: flex-start;
  }

  .service-image {
    width: 40%;
    margin-right: 5%;
    margin-bottom: 0;
  }

  .service-info {
    width: 55%;
  }
}

@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
}
.support-option img,
.campaign img,
.additional-support .campaign img {  /* Thêm selector cho phần "Keep your car in top form" */
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.support-option,
.campaign,
.additional-support .campaign {  /* Thêm selector cho phần "Keep your car in top form" */
  width: 100%;
  max-width: 600px;
  margin: 0 auto 20px;
}
.support-options,
.support-campaigns,
.additional-support .support-campaigns {  /* Thêm selector cho phần "Keep your car in top form" */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}
@media (max-width: 768px) {
  .support-options,
  .support-campaigns,
  .additional-support .support-campaigns {  /* Thêm selector cho phần "Keep your car in top form" */
    grid-template-columns: 1fr;
  }
  
  .support-option,
  .campaign,
  .additional-support .campaign {  /* Thêm selector cho phần "Keep your car in top form" */
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 13px;
  }

  .audi-container {
    padding: 10px;
  }
}