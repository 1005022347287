.audi_main_finance {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-finance-audi1 {
  font-size: 30px;
  font-weight: bold;
}
.finance-audi-img {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}
.finance-audi-img1 img,
.finance-audi-img2 img {
  width: 650px;
  height: 400px;
}

.but-audi {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-top: 20px;
}
.but1-audi {
  background-color: #65666d;
  color: #ffffff;
  width: 120px;
  height: 30px;
  text-align: center;
  padding-top: 4px;
  border-radius: 5px;
}
.but {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 30px;
}
.but1 {
  height: 50px;
  background-color: gray;
  margin-left: 250px;
  margin-right: 40px
}
.but2 {
  height: 50px;
}
.but2-audi {
  background-color: #425adb;
  color: #ffffff;
  width: 210px;
  height: 30px;
  text-align: center;
  padding-top: 4px;
  border-radius: 5px;
}
.title-finance-audi2 {
  margin-top: 20px;
}
@media screen and (max-width: 1024px) {
  .finance-audi-img1 img,
  .finance-audi-img2 img {
    width: 100%;
    max-width: 450px;
  }

  .but1-audi,
  .but2-audi {
    max-width: 180px;
  }
  .but2-audi {
    max-width: 210px;
  }
}
@media screen and (max-width: 768px) {
  .finance-audi-img {
    flex-direction: column;
    align-items: center;
  }

  .finance-audi-img1 img,
  .finance-audi-img2 img {
    width: 100%;
    max-width: 400px;
  }
  .finance-audi-img2 img {
    margin-left: -20px;
  }

  .but-audi {
    flex-direction: column;
    align-items: center;
  }

  .but1-audi,
  .but2-audi {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    max-width: 180px;
  }
  .but2-audi {
    max-width: 210px;
  }
}
