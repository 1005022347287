.mec_main_finance {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.box-finance1 {
  text-align: center;
}

.box-finance11 {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
}

.box-finance12 {
  margin-left: 40px;
  font-size: 15px;
}

.box-finance2,
.box-finance3 {
  display: flex;
  margin-top: 20px;
  margin-left: 50px;
}

.box-finance2-img img {
  width: 650px;
  height: 400px;
}

.box-finance211 {
  margin-left: 100px;
}

.box-finance2-text {
  text-align: center;
  margin-top: 20px;
}

.btn-contacus {
  height: 50px;
  background-color: gray;
  margin-left: 250px;
}

.btn-pdf {
  height: 50px;
}

.button-left {
  margin-top: 20px;
  display: flex;
  justify-content: right;
}

.button-right {
  margin-top: 20px;
  display: flex;
  justify-content: left;
}
.button-left,
.button-right {
  margin-top: 20px;
  display: flex;
}
.text1 {
  font-weight: bold;
}

/* Responsive Styles */
@media only screen and (max-width: 1024px) {
  .box-finance2,
  .box-finance3 {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .box-finance2-img img {
    width: 100%;
    height: auto;
  }

  .box-finance211 {
    margin-left: 0;
    margin-top: 20px;
  }

  .box-finance11 {
    font-size: 20px;
    font-weight: bold;
  }

  .box-finance2-img {
    width: 600px;
  }

  .text1 {
    font-weight: bold;
    margin-top: 20px;
  }
  .button-left,
  .button-right {
    justify-content: center; /* Center buttons on smaller screens */
    margin-top: 10px;
  }

  .btn-contacus,
  .btn-pdf {
    width: 100%; /* Make buttons full width */
    max-width: 300px; /* Limit max width for smaller screens */
  }
}

@media only screen and (max-width: 768px) {
  .box-finance2,
  .box-finance3 {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .box-finance2-img img {
    width: 100%;
    height: auto;
  }

  .box-finance211 {
    margin-left: 0;
    margin-top: 15px;
  }

  .box-finance11 {
    font-size: 20px;
  }

  .mec_main_finance {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .button-left,
  .button-right {
    justify-content: center; /* Center buttons on smaller screens */
    margin-top: 10px;
  }

  .btn-contacus,
  .btn-pdf {
    width: 100%; /* Full width on mobile */
  }
}
