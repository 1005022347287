.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.banner-container {
  position: relative;
  z-index: 1;
}
body,
html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main-content {
  flex: 1;
  position: relative;
  z-index: 2;
  margin-top: -10px;
  background-color: #fff;
  overflow: hidden;
}
.blur-background {
  filter: blur(5px); /* Làm mờ toàn bộ nền */
  transition: filter 0.3s ease;
  pointer-events: none; /* Ngăn tương tác với nền khi bị mờ */
  position: relative;
}

.ant-drawer {
  z-index: 1050; /* Đảm bảo Drawer có z-index cao hơn nội dung bị mờ */
}

.ant-modal {
  z-index: 1100; /* Modal có thể có z-index cao hơn Drawer nếu cần */
}
.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-dropdown,
.ant-card,
.ant-form-item,
.ant-menu-item {
  font-family: "Roboto", sans-serif !important;
}
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

/* Add styles for the loading spinner if needed */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
