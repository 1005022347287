.mec_main_container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.title1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: bold;
}
.content1 {
  display: flex;
  justify-content: space-between;
}
.content-left1-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.content-right1-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.content-left1-text {
  width: 650px;
  margin-top: 20px;
}
.content-right1-text {
  margin-top: 20px;
  width: 650px;
}
.content-left1-text li {
  list-style-type: disc;
  margin-left: 40px;
}
.content-right1-text li {
  list-style-type: disc;
  margin-left: 40px;
}
.content2 {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.content-left2-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.content-right2-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.content-right2 {
  width: 650px;
}
.content-left2-text {
  width: 650px;
  margin-top: 20px;
}
.content-right-text {
  margin-top: 20px;
}
.box2 {
  margin-top: 30px;
}
.title2 {
  text-align: center;
}
.title2-main {
  font-size: 30px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 20px;
}
.content3 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
content3-right-img {
  width: 650px;
}
.content3-right-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.content3-left-text {
  width: 650px;
}
.more3 {
  color: white;
  padding-left: 10px;
  width: 300px;
  border-radius: 5px;
  height: 50px;
  margin-top: 10px;
}
.content4 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.content4-right4-text {
  width: 650px;
  margin-top: 60px;
}
.content4-left4-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.more4 {
  background-color: rgb(45, 45, 243);
  color: white;
  padding-left: 10px;
  width: 400px;
  margin-top: 10px;
  border-radius: 5px;
}
.content4-right4-text {
  margin-left: 100px;
}
.content5 {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: space-between;
}
.more5 {
  background-color: rgb(45, 45, 243);
  color: white;
  padding-left: 10px;
  width: 300px;
  margin-top: 10px;
  border-radius: 5px;
}
.content5-right5-img img {
  width: 650px;
  height: 400px;
  border-radius: 5px;
}
.content5-left5-text {
  width: 650px;
  margin-top: 60px;
}

@media screen and (max-width: 1024px) {
  .content1,
  .content2,
  .content3,
  .content4,
  .content5 {
    flex-direction: column;
    align-items: center;
  }
  .content3,
  .content5 {
    flex-direction: column-reverse;
  }
  .content-right1,
  .content-right2,
  .content4-right4-text {
    margin-left: 0;
  }
  .content4-right4-text,
  .content5-left5-text {
    margin-top: 20px;
  }
  .content-left1-img img,
  .content-right1-img img,
  .content-left2-img img,
  .content-right2-img img,
  .content3-right-img img,
  .content4-left4-img img,
  .content5-right5-img img {
    max-width: 100%;
    height: auto;
  }

  .more3,
  .more4,
  .more5 {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .content-right1,
  .content-right2,
  .content4-right4-text {
    margin-left: 0;
  }

  .content-left1-img img,
  .content-right1-img img,
  .content-left2-img img,
  .content-right2-img img,
  .content3-right-img img,
  .content4-left4-img img,
  .content5-right5-img img {
    max-width: 100%;
    height: auto;
    margin-left: -5px;
    margin-top: 30px;
  }
  .more3,
  .more4,
  .more5 {
    width: 100%;
    text-align: center;
  }

  .title1,
  .title2-main {
    font-size: 24px;
    font-weight: bold;
  }
  .mec_main_container {
    padding: 0 15px;
  }
}
