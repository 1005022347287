body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.service-box {
  width: 31%;
 
 
  transition: transform 0.3s ease;
}


.service-box img {
  width: 100%;
  height: auto;
}

.service-title {
  font-weight: bold;
  margin-top: 20px;

}

.service-description {
  
  color: #555;
  margin-top: 10px;
}

.app-section {
  padding: 30px;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
}

.app-section {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.app-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-content h2
{
font-weight: bold;
font-size: 1.5vw;
}
.text-content {
  margin-bottom: 20px;
  text-align: center;
}

.benefitpara {
  display: flex;
  flex-direction: row; /* Side by side layout */
  justify-content: space-between;
  align-items: flex-start; /* Align to the top */
  text-align: left;
  width: 100%;
  max-width: 1000px; /* Full width but limited to 1000px */
  margin: 0 auto;
  padding: 20px 0;
}

.app-image {
  max-width: 40vw;
  height: auto;
  margin-right: 20px; /* Add space between image and text */
}

.benefitinfos {
  flex: 1; /* Take the remaining space */
}

.tinyheader {
  font-size: 20px;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.benefits {
  list-style-type: disc;
  padding-left: 20px; /* Ensure the bullets are aligned */
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.benefits li {
  margin-bottom: 10px;
}

.discover-button {
 width: auto;
 height: 50px;

}

.discover-button:hover {
  background-color: #00337a;
}

/* Responsive Adjustments */

@media (max-width: 1024px) {
  .service-box {
      width: 45%;
  }

  .benefitpara {
      flex-direction: column;
      align-items: center;
  }

  .app-image {
      max-width: 80vw;
      margin: 0 0 20px 0; /* Move image to top on smaller screens */
  }
}

@media (max-width: 768px) {
  .services {
      flex-direction: column;
      align-items: center;
  }

  .service-box {
      width: 80%;
  }

  .app-image {
      max-width: 90vw;
  }

  .service-title, .service-description {
      font-size: 4vw;
  }
}

@media (max-width: 480px) {
  .service-box {
      width: 100%;
  }

  .service-title {
      font-size: 5vw;
  }

  .service-description {
      font-size: 3vw;
  }

  .benefitpara {
      flex-direction: column;
      align-items: center;
  }

  .app-image {
      max-width: 100%;
      margin-bottom: 20px;
  }
}
