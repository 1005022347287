/* General container */
.contact-header-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px;
  text-align: left;
}

/* Main Heading */
.contact-header-container h1 {
  font-size: 42px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}

/* Introductory Paragraph */
.contact-header-container p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
}

.contact-header-container a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.contact-header-container a:hover {
  text-decoration: underline;
}

/* Info Section (Hours and Address) */
.info-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.hours, .address {
  width: 48%;
}

.hours h3, .address h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
}

.hours p, .address p {
  font-size: 16px;
  color: #555;
}

/* Contact Boxes Section */
.contact-boxes-section {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.contact-box {
  width: 30%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.contact-box h3 {
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
}

.contact-box p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.contact-box a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.contact-box a:hover {
  text-decoration: underline;
}

/* Separator Line */
.separator-line {
  border: none;
  border-top: 1px solid #ddd;
  margin: 40px 0;
}

/* Corporate Section */
.corporate-section h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}

.corporate-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.corporate-box {
  width: 48%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: left;
}

.corporate-box h3 {
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
}

.corporate-box p {
  font-size: 16px;
  color: #555;
}

.corporate-box a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.corporate-box a:hover {
  text-decoration: underline;
}

/* Fraud Warning */
.fraud-warning h3 {
  color: #c00000; /* Red color for fraud warning */
}

/* Owners Section */
.owners-section h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}

.owners-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.owners-box {
  width: 48%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: left;
}

.owners-box h3 {
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
}

.owners-box p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.owners-box a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.owners-box a:hover {
  text-decoration: underline;
}

/* Shop Section */
.shop-section h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}

.shop-boxes {
  display: flex;
  justify-content: space-between;
}

.shop-box {
  width: 48%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: left;
}

.shop-box h3 {
  font-size: 20px;
  color: black;
  margin-bottom: 10px;
}

.shop-box p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.shop-box a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.shop-box a:hover {
  text-decoration: underline;
}

/* FAQ Section */
.faq-section h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}

.faq-section p {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.faq-section a {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.faq-section a:hover {
  text-decoration: underline;
}
/* Error Reporting Section */
.error-reporting-section {
  margin-top: 40px;
}

.error-reporting-section h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
}

.error-reporting-section h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.error-reporting-section p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.error-reporting-section a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.error-reporting-section a span {
  font-size: 12px;
}

.error-reporting-section a:hover {
  text-decoration: underline;
}

