.warranty-page {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.warranty-header {
  position: relative;
  background-image: url("../assets/images/Warranty/Merc_banner.avif");
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  color: white;
}

.header-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
}

.header-text h1 {
  font-size: 48px;
  margin: 0;
  color: white;
}

.header-text p {
  font-size: 24px;
  margin: 5px 0 0;
  color: white;
}

/* Tab Styling */
.warranty-tabs {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  border-bottom: 1px solid #ddd;
  padding-left: 20px;
}

.tab {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.tab.active {
  font-weight: bold;
  border-bottom: 3px solid black;
}

.tab:hover {
  color: #000;
}
.warranty-content {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
}
.warranty-content h2 {
  font-size: 24px;
  padding-bottom: 20px;
}
.warranty-content-img img {
  width: 650px;
  height: 400px;
  object-fit: cover;
}
.warranty-details {
  width: 650px;
  line-height: 1.6;
}
.extended-warranty-label {
  display: inline-block;
  background-color: #6b5b95;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}
/* Benefits section */
.benefits-section {
  background-color: #ffffff;
  text-align: left; /* Align text to the left */
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 50px;
}

.benefits-section h2 {
  font-size: 36px; /* Increase font size for the heading */
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.benefits-section p {
  font-size: 16px; /* Adjust the font size for normal text */
  line-height: 1.8; /* Adjust line height for better readability */
  margin-bottom: 20px;
}

.benefits-section ul {
  list-style-type: disc;
  padding-left: 40px; /* Add padding for indentation */
  margin-bottom: 20px;
  font-size: 16px; /* Match font size with paragraph */
}

.benefits-section ul li {
  margin-bottom: 10px;
}

.separator-header {
  background-image: url("./BMW_banner.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: flex-start; /* Align content to the top */
  justify-content: flex-start; /* Align content to the left */
  color: white;
  position: relative;
  padding: 40px 20px;
}

.header-content {
  display: block; 
  background-color: rgba(
    0,
    0,
    0,
    0
  ); 
  padding: 20px;
  border-radius: 5px;
  max-width: 400px; 
}

.separator-header h1 {
  font-size: 28px; 
  line-height: 1.2; 
  margin: 0 0 10px 0; 
  font-weight: bold; 
}

.separator-header p {
  margin-bottom: 20px;
}
.btn-1{
  height: 50px;
  margin-bottom: 30px;
}
.contact-button {
  height: 50px;
  text-transform: uppercase;
}

.audi-warranty-header {
  background-image: url("../assets/images/Warranty/Audi_banner.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align content to the right */
  color: white;
  padding-right: 50px; /* Add space between the text and the edge */
}

.audi-warranty-overlay {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Transparent black background for readability */
  padding: 40px;
  text-align: right; /* Right-align text inside the overlay */
  color: white;
  max-width: 400px; /* Limit the width of the text block */
}

.audi-warranty-overlay h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.audi-warranty-overlay p {
  font-size: 16px;
  margin-bottom: 20px;
}

.audi-warranty-overlay h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.audi-warranty-overlay p {
  font-size: 16px;
}

/* Audi Logo */
.audi-logo {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.audi-logo img {
  width: 10vw; /* Adjust logo size */
}
.warranty-card {
  margin: 50px 0;
}
.warranty-card {
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Make each card's content horizontal */
}

.warranty-card-image {
  width: 650px; /* Image takes up 40% of the card */
  object-fit: cover;
  height: 400px; /* Prevents image distortion */
}

.warranty-card-content {
  width: 650px;
  padding-top: 50px; /* Content takes up the remaining 60% */
}

.warranty-card-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.warranty-card-content p {
  margin-bottom: 20px;
  line-height: 1.6;
}

.learn-more-button {
  padding: 10px 20px;
  background-color: #6b5b95;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.learn-more-button:hover {
  background-color: #59427a;
}
/* Porsche Approved Warranty Header */
.porsche-warranty-header {
  background-image: url("../assets/images/Warranty/Porsche_banner.jpg"); /* Placeholder image */
  background-size: cover;
  background-position: center;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.porsche-warranty-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  text-align: center;
  color: white;
}

.porsche-warranty-overlay h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.porsche-warranty-overlay p {
  font-size: 16px;
}

/* Tabs */
.warranty-tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ddd;
}

.tab {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  border-bottom: 3px solid transparent;
}

.tab:hover {
  color: #000;
}

.tab:focus {
  font-weight: bold;
  border-bottom: 3px solid black;
}

.content-section h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 50px;
  text-align: center;
}
.comtent-section p {
  font-size: 16px;
}
.content-image {
  width: 25vw;
  height: auto;
}
.warrant-content-image {
  flex: 1; /* Takes up the same amount of space as the text */
  max-width: 40%; /* Sets the maximum width of the image */
  object-fit: cover; /* Ensures the image doesn't stretch or morph */
}
.content-section p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: center;
}

/* Scrollable info cards */
.info-cards-scrollable {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.info-card {
  text-align: center;
  width: 300px;
}

.info-card img {
  width: 100%;
  height: auto;
}

.info-card h3 {
  font-size: 20px;
  margin-top: 15px;
}

.info-card p {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.6;
}
.opening-paragraph {
  align-items: center;
}
.opening-paragraph h2 {
  font-size: 24px; /* Increase font size for the heading */
  color: #333;
  font-weight: bold;
  max-width: 300px;
  margin-bottom: 20px;
  text-align: center;
  place-items: center;
}
.opening-paragraph p {
  max-width: 500px;
  margin-bottom: 20px;
  text-align: center;
  place-items: center;
}
.benefits-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.benefits-details {
  width: 650px;
}

.benefits-details h2 {
  font-size: 32px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.benefits-details ul {
  list-style-type: disc;
  line-height: 1.6;
}

.benefits-image {
  width: 650px;
  height: 400px;
  object-fit: cover;
}
/* Introduction Section */
.warranty-intro h2 {
  font-size: 36px; /* Bigger font for the title */
  font-weight: bold;
  margin-bottom: 20px;
}

.warranty-intro p {
  line-height: 1.8;
}
/* Warranty Types Section */
.warranty-types-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 50px;
}

.warranty-type-image {
  width: 650px;
  height: 450px;
  object-fit: cover;
}

.warranty-text {
  width: 650px;
  height: 400px;
  margin-top: -20px;
}

.warranty-text h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.warranty-text p {
  line-height: 1.8;
}

.warranty-text ul {
  list-style-type: disc;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
}
/* Base responsive adjustments for screens up to 1024px */
@media (max-width: 1024px) {
  /* Adjust the width of the main container */
  .warranty-page {
    max-width: 90%; /* Reduce overall width */
  }

  /* Adjust header for smaller screens */
  .warranty-header,
  .separator-header,
  .audi-warranty-header,
  .porsche-warranty-header {
    height: 300px; /* Reduce height for smaller screens */
    padding: 15px; /* Adjust padding */
  }

  /* Adjust header text size */
  .header-text h1,
  .separator-header h1,
  .audi-warranty-overlay h1,
  .porsche-warranty-overlay h1 {
    font-size: 32px; /* Smaller font size for smaller screens */
  }

  .header-text p,
  .separator-header p,
  .audi-warranty-overlay p,
  .porsche-warranty-overlay p {
    font-size: 18px; /* Adjust paragraph text size */
  }

  /* Stack elements vertically in content sections */
  .warranty-content,
  .benefits-row,
  .warranty-types-row {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
  }

  /* Adjust images for smaller screens */
  .warranty-content-img img,
  .warranty-card-image,
  .benefits-image,
  .warranty-type-image {
    width: 100%; /* Make images full width */
    height: auto; /* Keep aspect ratio */
  }

  /* Adjust content width */
  .warranty-details,
  .warranty-card-content,
  .warranty-text,
  .benefits-details {
    width: 100%; /* Full width for content sections */
    padding: 20px; /* Add some padding */
  }

  /* Adjust tab layout */
  .warranty-tabs {
    justify-content: center; /* Center tabs */
    padding-left: 0; /* Remove left padding */
  }

  /* Reduce button sizes */
  .contact-button,
  .learn-more-button {
    padding: 8px 15px; /* Smaller padding for buttons */
    font-size: 14px; /* Adjust font size */
  }

  /* Adjust info cards layout */
  .info-cards-scrollable {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards */
  }

  .info-card {
    width: 100%; /* Full width for each card */
    margin-bottom: 20px; /* Add space between cards */
  }

  /* Adjust font sizes globally */
  h2 {
    font-size: 28px; /* Smaller headings */
  }

  p {
    font-size: 14px; /* Adjust paragraph text */
  }

  .warranty-intro h2 {
    font-size: 32px; /* Adjust title size in intro section */
  }

  .warranty-text ul,
  .benefits-details ul {
    font-size: 14px; /* Smaller font for list items */
    padding-left: 20px; /* Indent list items */
  }

  /* Adjust margins for better spacing */
  .warranty-content,
  .benefits-section,
  .warranty-types-row,
  .info-cards-scrollable {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
/* Responsive adjustments for screens up to 768px */
@media (max-width: 768px) {
  /* Adjust the width of the main container */
  .warranty-page {
    max-width: 95%; /* Reduce width for smaller screens */
    margin: 0 auto;
  }

  /* Adjust header sections for smaller screens */
  .warranty-header,
  .separator-header,
  .audi-warranty-header,
  .porsche-warranty-header {
    height: 100%; /* Further reduce height */
  }
  .porsche-warranty-header {
    margin-left: -18px;
    width: 728px;
    height: 100%; /* Further reduce height */
  }
  /* Reduce text sizes in header sections */
  .header-text h1,
  .separator-header h1,
  .audi-warranty-overlay h1,
  .porsche-warranty-overlay h1 {
    font-size: 28px; /* Smaller font size for headings */
  }

  .header-text p,
  .separator-header p,
  .audi-warranty-overlay p,
  .porsche-warranty-overlay p {
    font-size: 16px; /* Smaller paragraph text size */
  }

  /* Stack flex content vertically */
  .warranty-content,
  .benefits-row,
  .warranty-types-row {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    margin-top: 20px;
  }

  /* Reduce image sizes for smaller screens */
  .warranty-content-img img,
  .warranty-card-image,
  .benefits-image,
  .warranty-type-image {
    width: 100%; /* Make images full width */
    height: auto; /* Keep aspect ratio */
  }

  /* Adjust text block width */
  .warranty-details,
  .warranty-card-content,
  .warranty-text,
  .benefits-details {
    width: 100%; /* Full width for text blocks */
    padding: 15px;
  }

  /* Adjust tab layout for smaller screens */
  .warranty-tabs {
    justify-content: space-around; /* Spread tabs evenly */
    padding: 0 10px;
  }

  /* Reduce button size */
  .contact-button,
  .learn-more-button {
    padding: 8px 15px;
    font-size: 14px;
  }

  /* Adjust info cards layout */
  .info-cards-scrollable {
    flex-direction: column; /* Stack info cards */
    align-items: center;
  }

  .info-card {
    width: 100%; /* Full width for each card */
    margin-bottom: 15px;
  }

  /* Reduce font sizes globally for smaller screens */
  h2 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }

  .warranty-intro h2 {
    font-size: 28px; /* Adjust title size in intro section */
  }

  .warranty-text ul,
  .benefits-details ul {
    font-size: 14px;
    padding-left: 20px;
  }

  /* Adjust spacing for smaller screens */
  .warranty-content,
  .benefits-section,
  .warranty-types-row,
  .info-cards-scrollable {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  /* Adjust margins and padding for smaller screens */
  .warranty-details,
  .benefits-details {
    margin-bottom: 20px;
  }

  .opening-paragraph,
  .benefits-row {
    flex-direction: column;
    text-align: center;
  }

  /* Adjust images for better fit on small screens */
  .warranty-content-img img {
    width: 100%;
    height: auto;
  }

  /* Adjust headings */
  h2,
  .warranty-text h2 {
    font-size: 24px;
  }

  .warranty-text p,
  .benefits-details p {
    font-size: 14px;
  }

  /* Adjust list items */
  .warranty-text ul,
  .benefits-details ul {
    font-size: 14px;
  }
  .warranty-card {
    display: flex;
    flex-direction: column;
  }
  #warranty-types img {
    width: 690px;
    height: auto;
  }
}
