@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
/* App.css hoặc global.css */

/* Import font Roboto từ Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* Áp dụng font cho toàn bộ body */
body, html {
  font-family: 'Roboto', sans-serif; /* Áp dụng font Roboto cho toàn bộ trang */
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Thiết lập typography cho các heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}
.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-dropdown,
.ant-card,
.ant-form-item,
.ant-menu-item {
  font-family: 'Roboto', sans-serif !important;
}
h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* Thiết lập cho các phần tử khác */
p,
a,
span,
li,
input,
button,
textarea {
  font-family: "Roboto", sans-serif;
}

/* Tùy chỉnh cho các liên kết */
a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

/* Tùy chỉnh cho các nút */
button {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

/* Responsive typography */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  h5,
  h6 {
    font-size: 1rem;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
